import { FunctionComponent, useEffect } from "react";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import SpeedIcon from "@mui/icons-material/Speed";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import UpdateIcon from "@mui/icons-material/Update";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import VerifiedIcon from "@mui/icons-material/Verified";
interface InterchangeModalProps {
  open: boolean;
  onClose: () => void;
}

const InterchangeModal: FunctionComponent<InterchangeModalProps> = ({
  open,
  onClose,
}) => {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [open]);

  return (
    <>
      <Overlay show={open} onClick={onClose} />
      <Root show={open}>
        <CloseDiv onClick={onClose}>
          <CloseIcon />
        </CloseDiv>
        <div style={{ padding: "24px" }}>
          <Title>Interchange & Downgrades Explained</Title>

          <BenefitsContainer>
            <BenefitItem>
              <IconWrapper>
                <AccountBalanceIcon
                  style={{ fontSize: "32px", color: "#5167F6" }}
                />
              </IconWrapper>
              <BenefitText>
                <BenefitTitle>Interchange Rates</BenefitTitle>
                <BenefitDescription>
                  Charged by card-issuing bank (e.g., Chase) every time a card
                  is used for a transaction. Interchange fees can vary based on
                  the type of card (e.g., rewards cards, debit cards, credit
                  cards), the transaction type, and the industry.
                </BenefitDescription>
              </BenefitText>
            </BenefitItem>

            <BenefitItem>
              <IconWrapper>
                <VerticalAlignBottomIcon
                  style={{ fontSize: "32px", color: "#FF6B6B" }}
                />
              </IconWrapper>
              <BenefitText>
                <BenefitTitle>Downgrades</BenefitTitle>
                <BenefitDescription>
                  Higher fees charged when transactions don't meet optimal
                  processing requirements
                </BenefitDescription>
              </BenefitText>
            </BenefitItem>
            <BenefitItem>
              <IconWrapper>
                <VerifiedIcon style={{ fontSize: "32px", color: "#10B981" }} />
              </IconWrapper>
              <BenefitText>
                <BenefitTitle>Optimization</BenefitTitle>
                <BenefitDescription>
                  Properly formatting transactions to qualify for the lowest
                  possible interchange rates
                </BenefitDescription>
              </BenefitText>
            </BenefitItem>
          </BenefitsContainer>

          <ConsentText>
            By optimizing how your transactions are processed and avoiding
            downgrades, you can significantly reduce your interchange fees and
            overall processing costs.
          </ConsentText>
        </div>
      </Root>
    </>
  );
};

const Root = styled.div<{ show: boolean }>`
  position: fixed;
  top: 50%;
  left: 50%;
  max-height: 90vh;
  overflow-y: auto;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 24px;
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.15);
  width: 90%;
  max-width: 600px;
  z-index: 1000;
  display: ${(props) => (props.show ? "block" : "none")};
  border: 1px solid rgba(81, 103, 246, 0.1);
`;

const CloseDiv = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 50%;
  transition: all 0.2s ease;
  :hover {
    background-color: rgba(0, 0, 0, 0.05);
    cursor: pointer;
  }
`;

const Title = styled.h2`
  font-size: 28px;
  font-weight: 800;
  color: #2a2a2a;
  margin: 0 0 24px 0;
  text-align: center;
  letter-spacing: -0.5px;
`;

const BenefitsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 24px;
`;

const BenefitItem = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px;
  background: rgba(81, 103, 246, 0.03);
  border-radius: 16px;
  transition: all 0.2s ease;

  &:hover {
    transform: translateX(4px);
    background: rgba(81, 103, 246, 0.05);
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(81, 103, 246, 0.08);
`;

const BenefitText = styled.div`
  flex: 1;
`;

const BenefitTitle = styled.h3`
  font-size: 18px;
  font-weight: 700;
  color: #2a2a2a;
  margin: 0 0 4px 0;
`;

const BenefitDescription = styled.p`
  font-size: 14px;
  color: #666;
  margin: 0;
  line-height: 1.5;
`;

const ConsentText = styled.p`
  font-size: 14px;
  color: #666;
  line-height: 1.6;
  background: rgba(16, 185, 129, 0.05);
  padding: 16px;
  border-radius: 12px;
  border: 1px solid rgba(16, 185, 129, 0.1);
  margin: 24px 0;
`;

const Overlay = styled.div<{ show: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(2px);
  z-index: 999;
  display: ${(props) => (props.show ? "block" : "none")};
  transition: all 0.3s ease;
`;

export default InterchangeModal;
