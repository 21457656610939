//@ts-nocheck
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  LabelList,
} from "recharts";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import {
  calculateInterchangePercent,
  calculateDuesPercent,
  calculatePassThroughPercent,
  calculateProcessingPercent,
  getBrandVolumeSplit,
  getBrandTransactionSplit,
  getCreditDebitSVolumeSplit,
  getCreditDebitTransactionSplit,
  createCardFeeTableRows,
  createAccountFeesTableRows,
} from "../../utils/report";
import { Root, Tile, Title, TileTitle } from "./Report.styled";
import Navbar from "../../components/Navbar/Navbar";
import { getReportAPI } from "../../api/report";
import LearnHowModal from "../../components/LearnHowModal/LearnHowModal";
import ProcessingFeesModal from "../../components/ProcessingFeesModal/ProcessingFeesModal";
import InterchangeModal from "../../components/InterchangeModal/InterchangeModal";

// ----------------------------------------------------------------
// New Processing Fees Tile Styled Components (PF = Processing Fees)
// ----------------------------------------------------------------

const PFBenefitsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 24px;
`;

const PFBenefitItem = styled.div<{ feeType?: "processing" | "passThrough" }>`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px;
  background: ${(props) =>
    props.feeType === "passThrough"
      ? "rgba(255, 107, 107, 0.08)"
      : "rgba(81, 103, 246, 0.08)"};
  border-radius: 16px;
  border: 1px solid
    ${(props) =>
      props.feeType === "passThrough"
        ? "rgba(255, 107, 107, 0.4)"
        : "rgba(81, 103, 246, 0.4)"};
  transition: all 0.2s ease;

  &:hover {
    transform: translateX(4px);
    background: ${(props) =>
      props.feeType === "passThrough"
        ? "rgba(255, 107, 107, 0.12)"
        : "rgba(81, 103, 246, 0.12)"};
  }
`;

// PFIconWrapper: hide icon on mobile devices
const PFIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 48px;
  min-height: 48px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(81, 103, 246, 0.08);

  @media (max-width: 768px) {
    display: none; /* Hide the icon on mobile */
  }
`;

// PFBenefitDescription: reduce description font size on mobile
const PFBenefitDescription = styled.p`
  font-size: 15px;
  color: #444;
  margin: 0;
  line-height: 1.8;

  @media (max-width: 768px) {
    font-size: 14px; /* Smaller font size on mobile */
  }
`;

const PFBenefitText = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const PFBenefitTitle = styled.h3`
  font-size: 20px;
  font-weight: 700;
  color: #2a2a2a;
  margin: 0 0 8px 0;
`;

// Updated PFFeeBadge styled component with an optional variant prop
const PFFeeBadge = styled.span<{ variant?: "purple" }>`
  background-color: ${(props) =>
    props.variant === "purple"
      ? "rgb(81, 103, 246)"
      : "rgba(255, 107, 107, 0.85)"};
  color: white;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 600;
  margin-left: 8px;
`;

const PFReferenceImagesContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  margin-top: 24px;
  /* Stack vertically on mobile screens */
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
`;

const PFReferenceImageItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PFReferenceImage = styled.img`
  width: 250px;
  height: auto;
`;

const PFReferenceLabel = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: #2a2a2a;
  margin-top: 8px;
`;

const PFConsentText = styled.p`
  font-size: 14px;
  color: #666;
  line-height: 1.6;
  background: rgba(16, 185, 129, 0.05);
  padding: 16px;
  border-radius: 12px;
  border: 1px solid rgba(16, 185, 129, 0.1);
  margin: 24px 0;
`;

// ----------------------------------------------------------------
// End Processing Fees Tile Styled Components
// ----------------------------------------------------------------

function closestLowerHalf(num) {
  // Truncate the number to get the whole part
  var wholeNumber = Math.floor(num);

  // Calculate the decimal part of the number
  var decimal = num - wholeNumber;

  // Check if the decimal part is greater than or equal to 0.5
  if (decimal >= 0.5) {
    return wholeNumber + 0.5;
  } else {
    return wholeNumber;
  }
}

const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    return (
      <div
        style={{
          backgroundColor: "rgba(255, 255, 255, 0.95)",
          padding: "20px",
          border: "1px solid #DEDFE3",
          borderRadius: "12px",
        }}
      >
        <p
          style={{
            fontWeight: "500",
            fontSize: isMobile ? "16px" : "18px",
            color: "rgb(81, 103, 246)",
            margin: "0px",
            padding: "0px",
          }}
        >{`Processing Fees: ${payload[1].value}%`}</p>
        <p style={{ fontSize: isMobile ? "16px" : "18px" }}>
          Fees charged by the payment processor for handling and facilitating
          the transaction.
        </p>
        <p
          style={{
            fontWeight: "500",
            fontSize: isMobile ? "16px" : "18px",
            color: "red",
            margin: "0px",
            padding: "0px",
          }}
        >{`Pass-Through Fees: ${payload[0].value}%`}</p>
        <p style={{ fontSize: isMobile ? "16px" : "18px" }}>
          Fees directly charged by card networks or banks, passed through
          without any markup.
        </p>
      </div>
    );
  }
  return null;
};

const FeeExplanationSection = () => (
  <div>
    <PFBenefitsContainer>
      <PFBenefitItem feeType="processing">
        <PFIconWrapper>
          <CurrencyExchangeIcon
            style={{ fontSize: "32px", color: "#5167F6" }}
          />
        </PFIconWrapper>
        <PFBenefitText>
          <PFBenefitTitle>
            Processor Fees <PFFeeBadge variant="purple">Negotiable</PFFeeBadge>
          </PFBenefitTitle>
          <PFBenefitDescription>
            Charged by the payment processor or service provider that
            facilitates transactions.
          </PFBenefitDescription>
        </PFBenefitText>
      </PFBenefitItem>

      <PFBenefitItem feeType="passThrough">
        <PFIconWrapper>
          <CreditCardIcon style={{ fontSize: "32px", color: "#FF6B6B" }} />
        </PFIconWrapper>
        <PFBenefitText>
          <PFBenefitTitle>
            Assessment Fees <PFFeeBadge>Pass‑through</PFFeeBadge>
          </PFBenefitTitle>
          <PFBenefitDescription>
            Charged by the card networks (e.g., Visa). Assessment fees are
            typically smaller than interchange fees and cover the cost of
            maintaining the payment infrastructure for the card network.
          </PFBenefitDescription>
        </PFBenefitText>
      </PFBenefitItem>

      <PFBenefitItem feeType="passThrough">
        <PFIconWrapper>
          <AccountBalanceIcon style={{ fontSize: "32px", color: "#FF6B6B" }} />
        </PFIconWrapper>
        <PFBenefitText>
          <PFBenefitTitle>
            Interchange Fees <PFFeeBadge>Pass‑through</PFFeeBadge>
          </PFBenefitTitle>
          <PFBenefitDescription>
            Charged by card-issuing bank (e.g., Chase) every time a card is used
            for a transaction. Interchange fees can vary based on the type of
            card (e.g., rewards cards, debit cards, credit cards), the
            transaction type, and the industry.
          </PFBenefitDescription>
        </PFBenefitText>
      </PFBenefitItem>
    </PFBenefitsContainer>

    <PFReferenceImagesContainer>
      <PFReferenceImageItem>
        <PFReferenceImage src="/image_4.png" alt="Interchange Fees" />
        <PFReferenceLabel>
          <span style={{ display: "flex", alignItems: "center" }}>
            <AccountBalanceIcon
              style={{
                fontSize: "16px",
                marginRight: "4px",
                color: "#FF6B6B",
              }}
            />
            Interchange Fees
          </span>
        </PFReferenceLabel>
      </PFReferenceImageItem>
      <PFReferenceImageItem>
        <PFReferenceImage src="/card_3.png" alt="Assessment Fees" />
        <PFReferenceLabel>
          <span style={{ display: "flex", alignItems: "center" }}>
            <CreditCardIcon
              style={{
                fontSize: "16px",
                marginRight: "4px",
                color: "#FF6B6B",
              }}
            />
            Assessment Fees
          </span>
        </PFReferenceLabel>
      </PFReferenceImageItem>
    </PFReferenceImagesContainer>
  </div>
);

const StatsContainer = styled.div`
  display: flex;
  flex-direction: ${() => (isMobile ? "column" : "row")};
  justify-content: space-between;
  gap: ${() => (isMobile ? "16px" : "24px")};
  background: rgba(81, 103, 246, 0.06);
  padding: ${() => (isMobile ? "16px" : "24px")};
  border-radius: 12px;
  margin-bottom: 24px;
`;

const StatItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StatLabel = styled.div`
  font-size: 14px;
  color: #666;
  font-weight: 500;
  margin-bottom: 4px;
`;

const StatValue = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: #2a2a2a;
  background: rgba(81, 103, 246, 0.06);
  padding: 4px 12px;
  border-radius: 8px;
  text-align: center;
  min-width: 120px;
`;

const Report = () => {
  const { _report = "" } = useParams();
  const navigate = useNavigate();
  const [report, setReport] = useState<any>({});
  const [isLoading, setIsLoading] = useState(true);
  const [showLearnHowModal, setShowLearnHowModal] = useState(false);
  const [showProcessingFeesModal, setShowProcessingFeesModal] = useState(false);
  const [showInterchangeModal, setShowInterchangeModal] = useState(false);

  const getReport = async (_report: String, tryCount: Number) => {
    try {
      const reportAPIResponse = await getReportAPI(_report);
      setReport(reportAPIResponse.data);
      setIsLoading(false);
    } catch (e) {
      if (tryCount < 3) {
        setTimeout(() => {
          getReport(_report, tryCount + 1);
        }, 1500);
      } else {
        alert("Report not available");
        navigate("/");
      }
    }
  };

  useEffect(() => {
    if (!_report) {
      navigate("/");
    }
    getReport(_report);
  }, [_report, navigate]);

  if (isLoading) {
    return (
      <div className="mb-5">
        <Navbar />
        <Root style={{ alignItems: "center" }}>
          <CircularProgress />
        </Root>
      </div>
    );
  }

  const { fee_navigator_response } = report;

  const passThruFees = Number(
    calculatePassThroughPercent(fee_navigator_response)
  );
  const processingFees = Number(
    calculateProcessingPercent(fee_navigator_response)
  );
  const effectiveRate =
    Number(fee_navigator_response?.["Effective Rate"]) * 100;

  const passThruFeesProposed = Number(
    fee_navigator_response["Percent Pass-Through Fees Proposed"] * 100
  ).toFixed(2);

  const feeBreakdownData = {
    name: "This Month",
    "Processor Fees": processingFees,

    "Pass-Through Fees": passThruFees,
  };

  let domainMin = 0;
  if (processingFees < 0.5) {
    domainMin = closestLowerHalf(effectiveRate) - 1;
  }

  let tickValues = [];

  if (domainMin) {
    for (
      let i = domainMin;
      i <= passThruFees + processingFees + 0.5;
      i += 0.5
    ) {
      tickValues.push(i);
    }
  } else {
    for (let i = domainMin; i <= passThruFees + processingFees + 1; i += 0.5) {
      tickValues.push(i);
    }
  }

  console.log("tickValues", tickValues, domainMin);

  const handleAgree = () => {
    // Handle agreement logic here
    setShowLearnHowModal(false);
  };

  // Custom label renderer that formats the value to two decimal places
  const renderCustomLabel = (props: any) => {
    const { x, y, width, height, value } = props;
    // Ensure value is a number and format it to 2 decimal places
    const formattedValue = Number(value).toFixed(2);
    return (
      <text
        x={x + width / 2}
        y={y + height / 2}
        fill="#FFFFFF"
        textAnchor="middle"
        dominantBaseline="middle"
        style={{ fontWeight: "bold", fontSize: isMobile ? "12px" : "14px" }}
      >
        {`${formattedValue}%`}
      </text>
    );
  };

  return (
    <div className="mb-5">
      <Navbar />
      <div>
        <Tile
          style={{
            maxWidth: isMobile ? "100%" : "1000px",
            margin: "0 auto",
            borderRadius: isMobile ? "16px" : "24px",
            border: "1px solid rgba(42, 42, 42, 0.08)",
            marginTop: "24px",
          }}
        >
          <TileTitle
            style={{
              fontSize: isMobile ? "24px" : "28px",
              fontWeight: "800",
              color: "#2A2A2A",
              letterSpacing: "-0.5px",
              position: "relative",
              paddingBottom: isMobile ? "10px" : "28px",
              marginBottom: isMobile ? "8px" : "36px",
              ":after": {
                content: '""',
                position: "absolute",
                bottom: "0",
                left: "0",
                width: "80px",
                height: "4px",
                background: "linear-gradient(90deg, #2A2A2A 0%, #4A4A4A 100%)",
                borderRadius: "2px",
              },
            }}
          >
            Rombis Report
          </TileTitle>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: "32px",
            }}
          >
            <div
              className="chart"
              style={{
                maxWidth: "700px",
                width: "100%",
                background: "rgba(255, 255, 255, 0.8)",
                backdropFilter: "blur(12px)",
                borderRadius: "24px",
                padding: isMobile ? "16px" : "24px",
                boxShadow:
                  "0 8px 32px rgba(81, 103, 246, 0.08), 0 2px 4px rgba(81, 103, 246, 0.04)",
                transition: "all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275)",
                ":hover": {
                  transform: "translateY(-4px) scale(1.01)",
                  boxShadow:
                    "0 12px 40px rgba(81, 103, 246, 0.12), 0 2px 6px rgba(81, 103, 246, 0.06)",
                },
                height: isMobile ? "730px" : "630px",
                paddingBottom: "150px",
                display: "block",
              }}
            >
              {/* <div
                style={{
                  fontSize: isMobile ? "16px" : "18px",
                  fontWeight: "600",
                  width: "100%",
                  padding: isMobile ? "12px 16px" : "16px 24px",
                  background:
                    "linear-gradient(135deg, #F0F3FF 0%, #E9EDFF 100%)",
                  borderRadius: isMobile ? "12px" : "16px",
                  color: "#5167F6",
                  marginBottom: isMobile ? "16px" : "24px",
                }}
              >
                Transaction Fee Breakdown
              </div> */}

              <div
                style={{
                  position: "absolute",
                  left: "-100px",
                  top: "40%",
                  transform: "rotate(-90deg) translateX(50%)",
                  transformOrigin: "right",
                  fontSize: isMobile ? "16px" : "18px",
                  fontWeight: "500",
                  color: "#666",
                  whiteSpace: "nowrap",
                  display: isMobile ? "none" : "block",
                }}
              >
                % Fee per Transaction
              </div>
              <StatsContainer>
                <StatItem>
                  <StatLabel>Transaction Volume</StatLabel>
                  <StatValue>
                    $
                    {fee_navigator_response?.[
                      "Calculated Total Volume"
                    ].toLocaleString(undefined, { maximumFractionDigits: 2 })}
                  </StatValue>
                </StatItem>
                <StatItem>
                  <StatLabel>Total Fees</StatLabel>
                  <StatValue>
                    $
                    {fee_navigator_response?.["Total Fees"].toLocaleString(
                      undefined,
                      {
                        maximumFractionDigits: 2,
                      }
                    )}
                  </StatValue>
                </StatItem>
                <StatItem>
                  <StatLabel>Effective Rate</StatLabel>
                  <StatValue>
                    {(
                      fee_navigator_response?.["Effective Rate"] * 100
                    ).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                    %
                  </StatValue>
                </StatItem>
              </StatsContainer>
              <BarChart
                style={{
                  overflow: "visible",
                }}
                width={isMobile ? 320 : 500}
                height={isMobile ? 380 : 420}
                data={[feeBreakdownData]}
                margin={{
                  top: 20,
                  right: isMobile ? 20 : 30,
                  left: isMobile ? 10 : 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid
                  strokeDasharray="3 3"
                  vertical={false}
                  stroke="rgba(81, 103, 246, 0.1)"
                />
                <XAxis
                  dataKey="name"
                  axisLine={false}
                  tickLine={false}
                  tick={{
                    fill: "#666",
                    fontSize: isMobile ? "14px" : "16px",
                    fontWeight: 500,
                  }}
                />
                <YAxis
                  axisLine={false}
                  tickLine={false}
                  tick={{
                    fill: "#666",
                    fontSize: isMobile ? "14px" : "16px",
                    fontWeight: 500,
                  }}
                />
                <Tooltip
                  cursor={{ fill: "rgba(81, 103, 246, 0.05)" }}
                  contentStyle={{
                    background: "rgba(255, 255, 255, 0.95)",
                    border: "1px solid rgba(81, 103, 246, 0.1)",
                    borderRadius: "8px",
                    boxShadow: "0 4px 12px rgba(81, 103, 246, 0.1)",
                    padding: "8px 12px",
                  }}
                />
                <Legend
                  verticalAlign="bottom"
                  height={120}
                  content={({ payload }) => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: isMobile ? "12px" : "18px",
                        padding: isMobile ? "12px" : "18px",
                        background: "rgba(255, 255, 255, 0.9)",
                        borderRadius: "12px",
                        boxShadow: "0 2px 8px rgba(81, 103, 246, 0.08)",
                        border: "1px solid rgba(81, 103, 246, 0.1)",
                        marginTop: "24px",
                        width: isMobile ? "calc(100% - 40px)" : "100%",
                        flexWrap: "wrap",
                      }}
                    >
                      {payload.reverse().map((entry, index) => (
                        <div
                          key={`legend-item-${index}`}
                          style={{
                            alignItems: "center",
                            gap: "8px",
                            flex: 1,
                            padding: isMobile ? "8px" : "12px",
                            background: "rgba(81, 103, 246, 0.04)",
                            borderRadius: "8px",
                            minWidth: "150px",
                            border: `1px solid ${
                              entry.value === "Processor Fees"
                                ? "rgba(81, 103, 246, 0.1)"
                                : "rgba(255, 107, 107, 0.1)"
                            }`,
                            transition: "all 0.2s ease",
                          }}
                        >
                          <div
                            style={{
                              display: "inline-block",
                              marginRight: "8px",
                              width: "12px",
                              height: "12px",
                              borderRadius: "3px",
                              background:
                                entry.value === "Processor Fees"
                                  ? "linear-gradient(180deg, #5167F6 0%, #7186FF 100%)"
                                  : "linear-gradient(180deg, #FF6B6B 0%, #FF8787 100%)",
                            }}
                          />
                          <span
                            style={{
                              fontSize: isMobile ? "15px" : "16px",
                              fontWeight: "600",
                              color:
                                entry.value === "Processor Fees"
                                  ? "#5167F6"
                                  : "#FF6B6B",
                            }}
                          >
                            {entry.value}
                          </span>
                        </div>
                      ))}
                    </div>
                  )}
                />
                {/* Pass-Through Fees Bar rendered first */}
                <Bar
                  dataKey="Pass-Through Fees"
                  fill="url(#passThruGradient)"
                  radius={[4, 4, 0, 0]}
                  stackId="stack"
                >
                  <LabelList
                    dataKey="Pass-Through Fees"
                    content={renderCustomLabel}
                  />
                </Bar>
                {/* Processing Fees Bar rendered second (on top) */}
                <Bar
                  dataKey="Processor Fees"
                  fill="url(#processingGradient)"
                  radius={[4, 4, 0, 0]}
                  stackId="stack"
                >
                  <LabelList
                    dataKey="Processor Fees"
                    content={renderCustomLabel}
                  />
                </Bar>
                <defs>
                  <linearGradient
                    id="processingGradient"
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                  >
                    <stop offset="0%" stopColor="#5167F6" />
                    <stop offset="100%" stopColor="#7186FF" />
                  </linearGradient>
                  <linearGradient
                    id="passThruGradient"
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                  >
                    <stop offset="0%" stopColor="#FF6B6B" />
                    <stop offset="100%" stopColor="#FF8787" />
                  </linearGradient>
                </defs>
              </BarChart>
            </div>

            <div
              style={{
                width: "100%",
                maxWidth: "700px",
                background: "rgba(255, 255, 255, 0.9)",
                backdropFilter: "blur(12px)",
                borderRadius: isMobile ? "16px" : "24px",
                padding: isMobile ? "20px" : "46px",
                boxShadow:
                  "0 8px 32px rgba(81, 103, 246, 0.12), 0 2px 6px rgba(81, 103, 246, 0.06)",
                transition: "all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275)",
                border: "1px solid rgba(81, 103, 246, 0.12)",
                ":hover": {
                  transform: isMobile ? "none" : "translateY(-4px) scale(1.02)",
                  boxShadow: isMobile
                    ? "0 8px 32px rgba(81, 103, 246, 0.12)"
                    : "0 16px 48px rgba(81, 103, 246, 0.16), 0 2px 8px rgba(81, 103, 246, 0.08)",
                },
              }}
            >
              <FeeExplanationSection />
            </div>

            <div
              style={{
                width: "100%",
                maxWidth: "700px",
                background: "rgba(255, 255, 255, 0.9)",
                backdropFilter: "blur(12px)",
                borderRadius: isMobile ? "16px" : "24px",
                padding: isMobile ? "20px" : "46px",
                boxShadow:
                  "0 8px 32px rgba(81, 103, 246, 0.12), 0 2px 6px rgba(81, 103, 246, 0.06)",
                transition: "all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275)",
                border: "1px solid rgba(81, 103, 246, 0.12)",
                ":hover": {
                  transform: isMobile ? "none" : "translateY(-4px) scale(1.02)",
                  boxShadow: isMobile
                    ? "0 8px 32px rgba(81, 103, 246, 0.12)"
                    : "0 16px 48px rgba(81, 103, 246, 0.16), 0 2px 8px rgba(81, 103, 246, 0.08)",
                },
              }}
            >
              {/* Monthly Processing Fees Section */}
              <div
                style={{
                  fontSize: isMobile ? "24px" : "32px",
                  fontWeight: "800",
                  background:
                    "linear-gradient(90deg, #5167F6 0%, #7186FF 70%, #8B9FFF 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  marginBottom: isMobile ? "8px" : "12px",
                  letterSpacing: "-0.5px",
                  animation: "pulse 2s infinite",
                }}
              >
                $
                {report?.fee_navigator_response?.[
                  "Processing Fees"
                ].toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })}
                <span
                  style={{
                    fontSize: isMobile ? "14px" : "18px",
                    fontWeight: "600",
                    marginLeft: isMobile ? "8px" : "12px",
                    opacity: 0.9,
                    color: "#666",
                    WebkitTextFillColor: "#666",
                    background: "#F5F7FF",
                    padding: isMobile ? "2px 8px" : "4px 12px",
                    borderRadius: "20px",
                    display: "inline-block",
                    marginTop: isMobile ? "8px" : "0",
                  }}
                >
                  {calculateProcessingPercent(fee_navigator_response)}% markup
                </span>
              </div>
              <div
                style={{
                  color: "#666",
                  paddingBottom: isMobile ? "16px" : "24px",
                  fontSize: isMobile ? "14px" : "16px",
                  fontWeight: "700",
                }}
              >
                in monthly processing fees
              </div>

              {/* Rombis Tip for Processing Fees */}
              <div
                style={{
                  background: "rgba(81, 103, 246, 0.04)",
                  border: "1px solid rgba(81, 103, 246, .3)",
                  borderRadius: isMobile ? "10px" : "12px",
                  padding: isMobile ? "12px 16px" : "14px 24px",
                  fontSize: isMobile ? "14px" : "16px",
                  color: "rgb(102, 102, 102)",
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  alignItems: isMobile ? "flex-start" : "center",
                  gap: isMobile ? "12px" : "8px",
                  marginBottom: isMobile ? "24px" : "32px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    justifyContent: "space-between",
                    flex: 1,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                      flex: 1,
                    }}
                  >
                    {!isMobile && (
                      <div
                        style={{
                          display: "inline",
                          fontWeight: "800",
                          letterSpacing: "0.5px",
                          textTransform: "uppercase",
                          fontSize: "inherit",
                        }}
                      >
                        ROMBIS TIP
                      </div>
                    )}
                    <p style={{ display: "inline", margin: "0px" }}>
                      Your processing fees can be negotiated lower.
                    </p>
                  </div>
                  <button
                    onClick={() => setShowLearnHowModal(true)}
                    style={{
                      background: "white",
                      border: "1px solid rgb(102, 102, 102)",
                      borderRadius: "8px",
                      padding: "6px 12px",
                      fontSize: "12px",
                      color: "rgb(102, 102, 102)",
                      cursor: "pointer",
                      fontWeight: "600",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Get Started
                  </button>
                </div>
              </div>

              {/* Divider between Processing Fees and Pass-Through Fees */}
              <hr
                style={{
                  border: "none",
                  borderTop: "2px solid rgba(81, 103, 246, 0.5)",
                  margin: isMobile ? "16px 0" : "24px 0",
                }}
              />

              {/* Monthly Pass-Through Fees Section */}
              <div
                style={{
                  fontSize: isMobile ? "24px" : "32px",
                  fontWeight: "800",
                  background:
                    "linear-gradient(90deg, #FF6B6B 0%, #FF8787 70%, #FFA5A5 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  marginBottom: isMobile ? "8px" : "12px",
                  letterSpacing: "-0.5px",
                  animation: "pulse 2s infinite",
                }}
              >
                $
                {report?.fee_navigator_response?.[
                  "Pass-Through Fees"
                ].toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })}
                <span
                  style={{
                    fontSize: isMobile ? "14px" : "18px",
                    fontWeight: "600",
                    marginLeft: isMobile ? "8px" : "12px",
                    opacity: 0.9,
                    color: "#666",
                    WebkitTextFillColor: "#666",
                    background: "#F5F7FF",
                    padding: isMobile ? "2px 8px" : "4px 12px",
                    borderRadius: "20px",
                    display: "inline-block",
                    marginTop: isMobile ? "8px" : "0",
                  }}
                >
                  {calculatePassThroughPercent(fee_navigator_response)}%
                </span>
              </div>
              <div
                style={{
                  color: "#666",
                  paddingBottom: isMobile ? "16px" : "24px",
                  fontSize: isMobile ? "14px" : "16px",
                  fontWeight: "700",
                }}
              >
                in monthly pass-through fees
              </div>

              {/* Rombis Tip for Pass-Through Fees */}
              {fee_navigator_response?.["Interchange optimization"] && (
                <div
                  style={{
                    background: "rgba(81, 103, 246, 0.04)",
                    border: "1px solid rgba(81, 103, 246, .3)",
                    borderRadius: isMobile ? "10px" : "12px",
                    padding: isMobile ? "12px 16px" : "14px 24px",
                    fontSize: isMobile ? "14px" : "16px",
                    color: "rgb(102, 102, 102)",
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    alignItems: isMobile ? "flex-start" : "center",
                    gap: isMobile ? "12px" : "8px",
                    marginBottom: isMobile ? "24px" : "32px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                      flex: 1,
                    }}
                  >
                    {!isMobile && (
                      <span
                        style={{
                          fontWeight: "800",
                          letterSpacing: "0.5px",
                          textTransform: "uppercase",
                          fontSize: "inherit",
                          width: "180px",
                        }}
                      >
                        ROMBIS TIP
                      </span>
                    )}
                    Based on your statement, you are likely to qualify for
                    reduced pass-through fees.
                  </div>
                  <button
                    onClick={() => setShowInterchangeModal(true)}
                    style={{
                      background: "white",
                      border: "1px solid rgb(102, 102, 102)",
                      borderRadius: "8px",
                      padding: "6px 12px",
                      fontSize: "12px",
                      color: "rgb(102, 102, 102)",
                      cursor: "pointer",
                      fontWeight: "600",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Learn More
                  </button>
                </div>
              )}

              {/* Divider after Pass-Through Fees Section */}
              <hr
                style={{
                  border: "none",
                  borderTop: "2px solid rgba(81, 103, 246, 0.5)",
                  margin: isMobile ? "16px 0" : "24px 0",
                }}
              />

              {/* Notification for lowering pass-through fees */}

              {report?.fee_navigator_response?.["Monthly Savings"] > 0 && (
                <div
                  style={{
                    background:
                      "linear-gradient(135deg, rgba(52, 211, 153, 0.04) 0%, rgba(52, 211, 153, 0.08) 100%)",
                    padding: isMobile ? "20px" : "36px",
                    borderRadius: isMobile ? "16px" : "24px",
                    border: "1px solid rgba(16, 185, 129, .3)",
                    display: "flex",
                    flexDirection: "column",
                    gap: isMobile ? "24px" : "32px",
                  }}
                >
                  {/* Header Section */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "16px",
                      padding: isMobile ? "0 0 8px 0" : "0 0 16px 0",
                      borderBottom: "2px solid rgba(16, 185, 129, 0.1)",
                    }}
                  >
                    <div
                      style={{
                        fontSize: isMobile ? "22px" : "28px",
                        fontWeight: "800",
                        background:
                          "linear-gradient(90deg, rgb(16, 185, 129) 0%, rgb(16, 185, 129) 100%) text",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                        lineHeight: "1.2",
                        letterSpacing: "-0.02em",
                      }}
                    >
                      Savings Estimate
                    </div>
                  </div>

                  {/* Main Savings Display */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "20px",
                      background: "rgba(16, 185, 129, 0.04)",
                      padding: isMobile ? "16px" : "24px",
                      borderRadius: "16px",
                      border: "1px solid rgba(16, 185, 129, 0.1)",
                      transition: "transform 0.2s ease",
                      cursor: "default",
                      ":hover": {
                        transform: "translateY(-2px)",
                      },
                    }}
                  >
                    <div
                      style={{
                        width: "56px",
                        height: "56px",
                        background:
                          "linear-gradient(135deg, rgb(16, 185, 129) 0%, rgb(16, 185, 129))",
                        borderRadius: "16px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        boxShadow: "0 8px 24px rgba(16, 185, 129, 0.2)",
                      }}
                    >
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 24 24"
                        fill="none"
                        style={{
                          animation: "pulse 2s infinite",
                        }}
                      >
                        <path
                          d="M12 2L2 7L12 12L22 7L12 2Z"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M2 17L12 22L22 17"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M2 12L12 17L22 12"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "4px",
                      }}
                    >
                      <div
                        style={{
                          fontSize: isMobile ? "32px" : "40px",
                          fontWeight: "800",
                          background:
                            "linear-gradient(90deg, rgb(16, 185, 129) 0%, rgb(16, 185, 129) 100%)",
                          WebkitBackgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                          lineHeight: "1.1",
                        }}
                      >
                        $
                        {report?.fee_navigator_response?.[
                          "Monthly Savings"
                        ].toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                        })}
                      </div>
                      <div
                        style={{
                          fontSize: isMobile ? "14px" : "16px",
                          color: "#666666",
                          fontWeight: "600",
                        }}
                      >
                        in potential monthly savings
                      </div>
                    </div>
                  </div>

                  {/* Projected Savings Section */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "16px",
                    }}
                  >
                    <div
                      style={{
                        fontSize: isMobile ? "16px" : "18px",
                        fontWeight: "600",
                        color: "#666666",
                      }}
                    >
                      Projected Savings
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: isMobile ? "column" : "row",
                        gap: isMobile ? "12px" : "16px",
                      }}
                    >
                      {[
                        { period: "1 Year", multiplier: 12 },
                        { period: "3 Year", multiplier: 36 },
                      ].map((duration) => (
                        <div
                          key={duration.period}
                          style={{
                            flex: 1,
                            background: "white",
                            padding: isMobile ? "20px" : "32px",
                            borderRadius: "12px",
                            boxShadow: "0 2px 8px rgba(16, 185, 129, 0.08)",
                            border: "1px solid rgba(16, 185, 129, 0.1)",
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                            transition: "all 0.2s ease",
                            cursor: "default",
                            ":hover": {
                              transform: "translateY(-2px)",
                              boxShadow: "0 4px 12px rgba(16, 185, 129, 0.12)",
                            },
                          }}
                        >
                          <div
                            style={{
                              fontSize: isMobile ? "20px" : "24px",
                              fontWeight: "700",
                              color: "#10B981",
                              display: "flex",
                              alignItems: "baseline",
                              gap: "4px",
                            }}
                          >
                            $
                            {(
                              report?.fee_navigator_response?.[
                                "Monthly Savings"
                              ] * duration.multiplier
                            ).toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                            })}
                          </div>
                          <div
                            style={{
                              fontSize: isMobile ? "14px" : "15px",
                              color: "#666666",
                              fontWeight: "500",
                            }}
                          >
                            {duration.period}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* Action Section */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "16px",
                      background: "rgba(16, 185, 129, 0.04)",
                      padding: isMobile ? "16px" : "24px",
                      borderRadius: "16px",
                      border: "1px solid rgba(16, 185, 129, 0.1)",
                    }}
                  >
                    <div
                      style={{
                        fontSize: isMobile ? "15px" : "16px",
                        color: "#666666",
                        fontWeight: "500",
                        lineHeight: "1.5",
                      }}
                    >
                      Ready to start saving?
                    </div>
                    <button
                      style={{
                        background:
                          "linear-gradient(90deg, rgb(16, 185, 129) 0%, rgb(16, 185, 129) 100%)",
                        color: "white",
                        border: "none",
                        borderRadius: "12px",
                        padding: isMobile ? "14px 20px" : "16px 24px",
                        fontSize: isMobile ? "15px" : "16px",
                        fontWeight: "600",
                        cursor: "pointer",
                        transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
                        boxShadow: "0 4px 12px rgba(74, 192, 56, 0.2)",
                        alignSelf: "flex-start",
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                      onClick={() => setShowLearnHowModal(true)}
                    >
                      Get Started
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        style={{
                          marginLeft: "4px",
                          transition: "transform 0.2s ease",
                        }}
                      >
                        <path
                          d="M3.33337 8H12.6667"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8 3.33331L12.6667 7.99998L8 12.6666"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Tile>
        {/* <div className="mt-4">
          <Tile
            style={{
              maxWidth: isMobile ? "100%" : "1000px",
              margin: "0 auto",
              borderRadius: isMobile ? "16px" : "24px",
              border: "1px solid rgba(81, 103, 246, 0.08)",
            }}
          >
            <TileTitle
              style={{
                fontSize: isMobile ? "24px" : "28px",
                fontWeight: "800",
                color: "#2A2A2A",
                letterSpacing: "-0.5px",
                position: "relative",
                paddingBottom: isMobile ? "10px" : "28px",
                marginBottom: isMobile ? "8px" : "36px",
                ":after": {
                  content: '""',
                  position: "absolute",
                  bottom: "0",
                  left: "0",
                  width: "80px",
                  height: "4px",
                  background:
                    "linear-gradient(90deg, #2A2A2A 0%, #4A4A4A 100%)",
                  borderRadius: "2px",
                },
              }}
            >
              Card Fees
            </TileTitle>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: isMobile ? "16px" : "20px",
                flexFlow: "wrap",
                width: "100%",
              }}
            >
              <div
                style={{
                  background: "rgba(255, 255, 255, 0.9)",
                  backdropFilter: "blur(12px)",
                  borderRadius: isMobile ? "16px" : "24px",
                  padding: isMobile ? "20px" : "32px",
                  boxShadow:
                    "0 8px 32px rgba(81, 103, 246, 0.12), 0 2px 6px rgba(81, 103, 246, 0.06)",
                  transition:
                    "all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275)",
                  border: "1px solid rgba(81, 103, 246, 0.12)",
                  width: "100%",
                  maxWidth: isMobile ? "100%" : "800px",
                  ":hover": {
                    transform: isMobile ? "none" : "translateY(-4px)",
                    boxShadow: isMobile
                      ? "0 8px 32px rgba(81, 103, 246, 0.12)"
                      : "0 16px 48px rgba(81, 103, 246, 0.16), 0 2px 8px rgba(81, 103, 246, 0.08)",
                  },
                }}
              >
                <div
                  style={{
                    fontSize: isMobile ? "16px" : "18px",
                    fontWeight: "600",
                    width: "100%",
                    padding: isMobile ? "12px 16px" : "16px 24px",
                    background:
                      "linear-gradient(135deg, #F0F3FF 0%, #E9EDFF 100%)",
                    borderRadius: isMobile ? "12px" : "16px",
                    color: "#5167F6",
                    marginBottom: isMobile ? "16px" : "24px",
                  }}
                >
                  Card Volume Breakdown
                </div>
                <CardVolumeChart
                  reportData={fee_navigator_response}
                  fee_navigator_response={fee_navigator_response}
                />
              </div>
            </div>
          </Tile>
        </div>
        <div className="mt-4">
          <Tile
            style={{
              maxWidth: isMobile ? "100%" : "1000px",
              margin: "0 auto",
              borderRadius: isMobile ? "16px" : "24px",
              border: "1px solid rgba(81, 103, 246, 0.08)",
            }}
          >
            <TileTitle
              style={{
                fontSize: isMobile ? "24px" : "28px",
                fontWeight: "800",
                color: "#2A2A2A",
                letterSpacing: "-0.5px",
                position: "relative",
                paddingBottom: isMobile ? "10px" : "28px",
                marginBottom: isMobile ? "8px" : "36px",
                ":after": {
                  content: '""',
                  position: "absolute",
                  bottom: "0",
                  left: "0",
                  width: "80px",
                  height: "4px",
                  background:
                    "linear-gradient(90deg, #2A2A2A 0%, #4A4A4A 100%)",
                  borderRadius: "2px",
                },
              }}
            >
              Account Fees
            </TileTitle>
            <div className={isMobile ? "mt-3" : "mt-4"}>
              <div
                style={{
                  background: "rgba(255, 255, 255, 0.9)",
                  backdropFilter: "blur(12px)",
                  maxWidth: "800px",
                  margin: "0 auto",
                  borderRadius: isMobile ? "16px" : "24px",
                  padding: isMobile ? "20px" : "32px",
                  boxShadow:
                    "0 8px 32px rgba(81, 103, 246, 0.12), 0 2px 6px rgba(81, 103, 246, 0.06)",
                  transition:
                    "all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275)",
                  border: "1px solid rgba(81, 103, 246, 0.12)",
                  ":hover": {
                    transform: isMobile ? "none" : "translateY(-4px)",
                    boxShadow: isMobile
                      ? "0 8px 32px rgba(81, 103, 246, 0.12)"
                      : "0 16px 48px rgba(81, 103, 246, 0.16), 0 2px 8px rgba(81, 103, 246, 0.08)",
                  },
                }}
              >
                <div
                  style={{
                    fontSize: isMobile ? "16px" : "18px",
                    fontWeight: "800",
                    width: "100%",
                    padding: isMobile ? "12px 16px" : "16px 24px",
                    background:
                      "linear-gradient(135deg, #F0F3FF 0%, #E9EDFF 100%)",
                    borderRadius: isMobile ? "12px" : "16px",
                    color: "#5167F6",
                    marginBottom: isMobile ? "16px" : "24px",
                  }}
                >
                  Account Monthly Service Fees
                </div>
                <AccountFeesTable reportData={fee_navigator_response} />
              </div>
            </div>
          </Tile>
        </div> */}
      </div>

      <LearnHowModal
        open={showLearnHowModal}
        onClose={() => setShowLearnHowModal(false)}
        onAgree={handleAgree}
      />
      <ProcessingFeesModal
        open={showProcessingFeesModal}
        onClose={() => setShowProcessingFeesModal(false)}
      />
      <InterchangeModal
        open={showInterchangeModal}
        onClose={() => setShowInterchangeModal(false)}
      />
    </div>
  );
};

const CardTransactionChart = ({ reportData }) => {
  const { data, label } = getBrandTransactionSplit(reportData);
  return <TransactionCountTable rows={data} label={label} />;
};

const CardVolumeChart = ({ reportData, fee_navigator_response }) => {
  const { data, label } = getBrandVolumeSplit(reportData);
  return (
    <div
      className="rombis-pie"
      style={{
        border: "1px solid #DEDFE3",
        borderRadius: "12px",
        overflow: "hidden",
        flex: 1,
        minWidth: "300px",
      }}
    >
      <RombisPieChart data={data} label={label} />
      <CardTransactionChart reportData={fee_navigator_response} />
    </div>
  );
};

const CreditDebitVolumeChart = ({ reportData }) => {
  const { data, label } = getCreditDebitSVolumeSplit(reportData);
  return <RombisPieChart data={data} label={label} />;
};

const CreditDebitTransactionChart = ({ reportData }) => {
  const { data, label } = getCreditDebitTransactionSplit(reportData);
  return <TransactionCountTable rows={data} label={label} />;
};

const RombisPieChart = ({ data, label }) => {
  const renderLabel = (cell: Object) => {
    const { cx, cy, midAngle, outerRadius, labelIcon, value, type } = cell;
    const RADIAN = Math.PI / 180;
    const radius = outerRadius + 30;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    let renderValue;
    switch (type) {
      case "Currency":
        renderValue = `$${
          value?.toLocaleString(undefined, { maximumFractionDigits: 2 }) ||
          value
        }`;
        break;
      default:
        renderValue = value;
    }
    return (
      <g>
        <image
          x={x - 12}
          y={y - 12}
          width={50}
          height={34}
          xlinkHref={labelIcon}
        />
        <LabelList dataKey="name" content={false} />
      </g>
    );
  };

  return (
    <div>
      <PieChart width={isMobile ? 350 : 500} height={isMobile ? 325 : 400}>
        <Pie
          dataKey="value"
          data={data}
          labelLine={true}
          label={renderLabel}
          innerRadius={isMobile ? 35 : 80}
          outerRadius={isMobile ? 80 : 130}
          paddingAngle={8}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
      </PieChart>
    </div>
  );
};

const TransactionCountTable = ({ rows, label, showLabel }) => {
  return (
    <div
      style={{
        borderRadius: isMobile ? "16px" : "24px",
        overflow: "hidden",
        height: "fit-content",
        flex: 1,
        minWidth: "300px",
        border: "1px solid rgba(42, 42, 42, 0.08)",
        background: "rgba(255, 255, 255, 0.9)",
        backdropFilter: "blur(12px)",
        boxShadow:
          "0 8px 32px rgba(42, 42, 42, 0.06), 0 2px 4px rgba(42, 42, 42, 0.02)",
      }}
    >
      {showLabel && (
        <div
          style={{
            fontSize: isMobile ? "15px" : "18px",
            fontWeight: "700",
            width: "100%",
            padding: isMobile ? "16px 20px" : "24px 32px",
            background: "linear-gradient(135deg, #F5F5F5 0%, #EFEFEF 100%)",
            color: "#2A2A2A",
            letterSpacing: "-0.3px",
          }}
        >
          {label}
        </div>
      )}
      <TableContainer
        component={Paper}
        style={{
          boxShadow: "none",
          background: "transparent",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                align="left"
                style={{
                  fontSize: isMobile ? "14px" : "18px",
                  fontWeight: "700",
                  color: "rgb(102, 102, 102)",
                  letterSpacing: "-0.3px",
                  borderBottom: "2px solid rgba(42, 42, 42, 0.08)",
                  padding: isMobile ? "16px 20px" : "24px 32px",
                  background: "rgba(81, 103, 246, 0.04)",
                }}
              >
                Card Brand
              </TableCell>
              <TableCell
                align="center"
                style={{
                  fontSize: isMobile ? "14px" : "18px",
                  fontWeight: "700",
                  letterSpacing: "-0.3px",
                  borderBottom: "2px solid rgba(42, 42, 42, 0.08)",
                  padding: isMobile ? "16px 20px" : "24px 32px",
                  background: "rgba(81, 103, 246, 0.04)",
                }}
              >
                Count
              </TableCell>
              <TableCell
                align="center"
                style={{
                  fontSize: isMobile ? "14px" : "18px",
                  fontWeight: "700",
                  letterSpacing: "-0.3px",
                  borderBottom: "2px solid rgba(42, 42, 42, 0.08)",
                  padding: isMobile ? "16px 20px" : "24px 32px",
                  background: "rgba(81, 103, 246, 0.04)",
                }}
              >
                Volume
              </TableCell>
              <TableCell
                align="center"
                style={{
                  fontSize: isMobile ? "14px" : "18px",
                  fontWeight: "700",
                  letterSpacing: "-0.3px",
                  borderBottom: "2px solid rgba(42, 42, 42, 0.08)",
                  padding: isMobile ? "16px 20px" : "24px 32px",
                  background: "rgba(81, 103, 246, 0.04)",
                }}
              >
                Txn Fee
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                key={row.name}
                style={{
                  background:
                    index % 2 === 0 ? "rgba(42, 42, 42, 0.01)" : "transparent",
                  transition: "all 0.2s ease",
                  ":hover": {
                    background: "rgba(42, 42, 42, 0.03)",
                  },
                }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  style={{
                    fontSize: isMobile ? "13px" : "18px",
                    fontWeight: "500",
                    color: "#2A2A2A",
                    borderBottom: "1px solid rgba(42, 42, 42, 0.06)",
                    padding: isMobile ? "16px 20px" : "24px 32px",
                    gap: "12px",
                  }}
                >
                  <img width={42} height={26} src={row.labelIcon} alt="card" />
                  <span style={{ paddingLeft: "12px" }}>{row.name}</span>
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    fontSize: isMobile ? "13px" : "18px",
                    fontWeight: "600",
                    color: "#2A2A2A",
                    borderBottom: "1px solid rgba(42, 42, 42, 0.06)",
                    padding: isMobile ? "16px 20px" : "24px 32px",
                  }}
                >
                  {row.value}
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    fontSize: isMobile ? "15px" : "18px",
                    fontWeight: "600",
                    color: "#2A2A2A",
                    borderBottom: "1px solid rgba(42, 42, 42, 0.06)",
                    padding: isMobile ? "16px 20px" : "24px 32px",
                  }}
                >
                  <span
                    style={{
                      background: "rgba(42, 42, 42, 0.04)",
                      padding: isMobile ? "4px 8px" : "6px 12px",
                      borderRadius: "6px",
                      display: "inline-block",
                      transition: "all 0.2s ease",
                      ":hover": {
                        background: "rgba(42, 42, 42, 0.06)",
                      },
                    }}
                  >
                    $
                    {typeof row.volumeValue === "number"
                      ? row.volumeValue.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : row.volumeValue}
                  </span>
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    fontSize: isMobile ? "15px" : "18px",
                    fontWeight: "600",
                    color: "#2A2A2A",
                    borderBottom: "1px solid rgba(42, 42, 42, 0.06)",
                    padding: isMobile ? "16px 20px" : "24px 32px",
                  }}
                >
                  <span
                    style={{
                      background: "rgba(42, 42, 42, 0.04)",
                      padding: isMobile ? "4px 8px" : "6px 12px",
                      borderRadius: "6px",
                      display: "inline-block",
                      transition: "all 0.2s ease",
                      ":hover": {
                        background: "rgba(42, 42, 42, 0.06)",
                      },
                    }}
                  >
                    {row.txnFeeValue}
                  </span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const CardFeeTable = ({ reportData }) => {
  const rows = createCardFeeTableRows(reportData);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="customized table" style={{ fontSize: "18px" }}>
        <TableHead>
          <TableRow>
            <TableCell align="left" style={{ fontWeight: 700 }}>
              Card Brand
            </TableCell>
            <TableCell align="right">Transaction Fee</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow
              key={row.name}
              style={{
                background:
                  index % 2 === 0 ? "white" : "rgb(222, 223, 227, .25)",
              }}
            >
              <TableCell component="th" scope="row">
                <img width={42} height={26} src={row.labelIcon} alt="card" />{" "}
                <span>{row.name}</span>
              </TableCell>
              <TableCell align="right" style={{ fontWeight: 700 }}>
                {row.value}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const AccountFeesTable = ({ reportData }) => {
  const rows = createAccountFeesTableRows(reportData);

  return (
    <div
      style={{
        borderRadius: isMobile ? "16px" : "24px",
        overflow: "hidden",
        height: "fit-content",
        flex: 1,
        minWidth: "300px",
        border: "1px solid rgba(42, 42, 42, 0.08)",
        background: "rgba(255, 255, 255, 0.9)",
        backdropFilter: "blur(12px)",
        boxShadow:
          "0 8px 32px rgba(42, 42, 42, 0.06), 0 2px 4px rgba(42, 42, 42, 0.02)",
      }}
    >
      <TableContainer
        component={Paper}
        style={{
          boxShadow: "none",
          background: "transparent",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                align="left"
                style={{
                  fontSize: isMobile ? "15px" : "18px",
                  fontWeight: "700",
                  color: "#2A2A2A",
                  letterSpacing: "-0.3px",
                  borderBottom: "2px solid rgba(42, 42, 42, 0.08)",
                  padding: isMobile ? "16px 20px" : "24px 32px",
                  background: "rgba(42, 42, 42, 0.02)",
                }}
              >
                Fee Type
              </TableCell>
              <TableCell
                align="right"
                style={{
                  fontSize: isMobile ? "15px" : "18px",
                  fontWeight: "700",
                  color: "#2A2A2A",
                  letterSpacing: "-0.3px",
                  borderBottom: "2px solid rgba(42, 42, 42, 0.08)",
                  padding: isMobile ? "16px 20px" : "24px 32px",
                  background: "rgba(42, 42, 42, 0.02)",
                }}
              >
                Amount
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                key={row.name}
                style={{
                  background:
                    index % 2 === 0 ? "rgba(42, 42, 42, 0.01)" : "transparent",
                  transition: "all 0.2s ease",
                  ":hover": {
                    background: "rgba(42, 42, 42, 0.03)",
                  },
                }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  style={{
                    fontSize: isMobile ? "15px" : "18px",
                    fontWeight: "500",
                    color: "#2A2A2A",
                    borderBottom: "1px solid rgba(42, 42, 42, 0.06)",
                    padding: isMobile ? "16px 20px" : "24px 32px",
                  }}
                >
                  {row.name}
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    fontSize: isMobile ? "15px" : "18px",
                    fontWeight: "600",
                    color: "#2A2A2A",
                    borderBottom: "1px solid rgba(42, 42, 42, 0.06)",
                    padding: isMobile ? "16px 20px" : "24px 32px",
                  }}
                >
                  <span
                    style={{
                      background: "rgba(42, 42, 42, 0.04)",
                      padding: isMobile ? "4px 8px" : "6px 12px",
                      borderRadius: "6px",
                      display: "inline-block",
                      transition: "all 0.2s ease",
                      ":hover": {
                        background: "rgba(42, 42, 42, 0.06)",
                      },
                    }}
                  >
                    {typeof row.value === "number"
                      ? row.value.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : row.value}
                  </span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Report;
